window.$ = window.jQuery = jQuery;
import "default-passive-events";
const pace = require("pace-js");


//Pace
pace.start();

//LazyLoad
const YTObserver = new IntersectionObserver((entries, self) => {
	entries.forEach(entry => {
		if (entry.isIntersecting) {
			loadThumb(entry.target);
			self.unobserve(entry.target);
		}
	});
});
document.querySelectorAll(".lazy-youtube-embed").forEach((v) => {
	let split = v.getAttribute("href").split("/");

	if (split.length > 0) {
		YTObserver.observe(v);
		let ytId = split[split.length - 1];

		v.addEventListener("click", function(evt) {
			evt.preventDefault();
			let embed = document.createElement("iframe");
			embed.setAttribute("src", `https://www.youtube.com/embed/${ytId}?autoplay=1&fs=1&rel=0`);
			embed.setAttribute("frameborder", "0");
			embed.setAttribute("allow", "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture;fullscreen");
			embed.setAttribute("width", this.offsetWidth);
			embed.setAttribute("height", this.offsetHeight);
			this.innerHTML = "";
			this.appendChild(embed);
		}, false);
	}
});
const loadThumb = (v) => {
	if (!v.getAttribute("loaded")) {
		let split = v.getAttribute("href").split("/");
		let ytId = split[split.length - 1];
		let thumbNode = document.createElement("img");
		let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
		let quality = "sd";
		if (connection) {
			switch (connection.effectiveType) {
			case "4g":
				quality = "maxres";
				break;
			case "3g":
				quality = "sd";
				break;
			default:
				quality = "hq";
			}
		}
		thumbNode.setAttribute("src", `https://img.youtube.com/vi/${ytId}/${quality}default.jpg`);
		v.innerHTML = "";
		v.appendChild(thumbNode);

		let playImg = document.createElement("div");
		playImg.innerHTML = "<svg height=\"100%\" version=\"1.1\" viewBox=\"0 0 68 48\" width=\"100%\"><path class=\"ytp-large-play-button-bg\" d=\"M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z\" fill=\"#FF0000\"></path><path d=\"M 45,24 27,14 27,34\" fill=\"#fff\"></path></svg>";
		playImg.setAttribute("class", "ytlight__play");
		v.appendChild(playImg);
	}
};

var lazyImages;
lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));
if ("IntersectionObserver" in window) {
	let SingleImg = new IntersectionObserver(function(entries, self) {
		entries.forEach(function(entry) {
			if (entry.isIntersecting) {
				entry.target.src = entry.target.dataset.src;
				entry.target.classList.add("fade-in");
				entry.target.classList.remove("lazy");
				self.unobserve(entry.target);
			}
		});
	});
	lazyImages.forEach(function(lazyImage) {
		SingleImg.observe(lazyImage);
	});
} else {
	lazyImages.forEach(function(image) {
		image.src = image.dataset.srcset;
	});
}

function lazy() {
	lazyImages = [].slice.call(document.querySelectorAll("picture.lazy"));
	if ("IntersectionObserver" in window) {
		let PictureSet = new IntersectionObserver(function(entries, self) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					entry.target.querySelectorAll("source").forEach(function(source_entry) {
						source_entry.srcset = source_entry.dataset.srcset;
						source_entry.classList.add("fade-in");
					});
					entry.target.querySelector("img").src = entry.target.querySelector("img").dataset.src;
					entry.target.classList.remove("lazy");
					self.unobserve(entry.target);
				}
			});
		});
		lazyImages.forEach(function(lazyImage) {
			PictureSet.observe(lazyImage);
		});
	} else {
		lazyImages.forEach(function(image) {
			image.querySelectorAll("source").forEach(function(source_entry) {
				source_entry.srcset = source_entry.dataset.srcset;
				source_entry.classList.add("fade-in");
			});
			image.querySelector("img").src = image.querySelector("img").dataset.src;
		});
	}
}
lazy(); 

function lazybg() {
	lazyImages = [].slice.call(document.querySelectorAll(".lazyload_bgimage"));
	if ("IntersectionObserver" in window) {
		let BGImage = new IntersectionObserver(function(entries, self) {
			entries.forEach(function(entry) {
				if (entry.isIntersecting) {
					if (!entry.target.classList.contains("visible")) {
						entry.target.classList.add("visible");
						self.unobserve(entry.target);
					}
				}
			});
		});
		lazyImages.forEach(function(lazyImage) {
			BGImage.observe(lazyImage);
		});
	} else {
		lazyImages.forEach(function(image) {
			if (!image.classList.contains("visible")) {
				image.classList.add("visible");
			}
		});
	}
}
lazybg();

//AjaxCache
(function($, window) {
	var genCacheKey = function(options) {
		var url;

		// If cacheKey is specified, and a function, return the result of calling that function
		// as the cacheKey. Otherwise, just return the specified cacheKey as-is.
		if (options.cacheKey) {
			return (typeof options.cacheKey === "function") ?
				options.cacheKey(options) : options.cacheKey;
		}

		url = options.url.replace(/jQuery.*/, "");

		// Strip _={timestamp}, if cache is set to false
		if (options.cache === false) {
			url = url.replace(/([?&])_=[^&]*/, "");
		}

		return url + options.type + (options.data || "");
	};


	var getStorage = function(storage) {
		if (!storage) return false;
		if (storage === true) return window.localStorage;
		if (typeof storage === "object" && "getItem" in storage &&
			"removeItem" in storage && "setItem" in storage) {
			return storage;
		}
		throw new TypeError("localCache must either be a boolean value, " +
			"or an object which implements the Storage interface.");
	};

	var removeFromStorage = function(storage, cacheKey) {
		storage.removeItem(cacheKey);
		storage.removeItem(cacheKey + "cachettl");
		storage.removeItem(cacheKey + "dataType");
	};

	$.ajaxPrefilter(function(options, originalOptions, jqXHR) {
		var storage = getStorage(options.localCache),
			hourstl = options.cacheTTL || 5,
			cacheKey = options.cacheKey = genCacheKey(options),
			cacheValid = options.isCacheValid,
			responseValid = options.isResponseValid,
			thenResponse = options.thenResponse || null,
			ttl,
			value;

		if (!storage) return;
		ttl = storage.getItem(cacheKey + "cachettl");

		if (cacheValid && typeof cacheValid === "function" && !cacheValid()) {
			removeFromStorage(storage, cacheKey);
			ttl = 0;
		}

		if (ttl && ttl < +new Date()) {
			removeFromStorage(storage, cacheKey);
			ttl = 0;
		}

		value = storage.getItem(cacheKey);
		if (!value) {
			// If value not in the cache, add a then block to request to store the results on success.
			jqXHR.then(thenResponse).then(function(data, status, jqXHR) {
				var strdata = data,
					dataType = options.dataType || jqXHR.getResponseHeader("Content-Type") || "text/plain";

				if (!(responseValid && typeof responseValid === "function" && !responseValid(data, status, jqXHR))) {

					if (dataType.toLowerCase().indexOf("json") !== -1) strdata = JSON.stringify(data);

					// Save the data to storage catching exceptions (possibly QUOTA_EXCEEDED_ERR)
					try {
						storage.setItem(cacheKey, strdata);
						// Store timestamp and dataType
						storage.setItem(cacheKey + "cachettl", +new Date() + 1000 * 60 * 60 * hourstl);
						storage.setItem(cacheKey + "dataType", dataType);
					} catch (e) {
						// Remove any incomplete data that may have been saved before the exception was caught
						removeFromStorage(storage, cacheKey);
						console.log("Cache Error:" + e, cacheKey, strdata);
					}
				}
			});
		}
	});

	$.ajaxTransport("+*", function(options) {
		if (options.localCache) {
			var cacheKey = options.cacheKey,
				storage = getStorage(options.localCache),
				dataType = options.dataType || storage.getItem(cacheKey + "dataType") || "text",
				value = (storage) ? storage.getItem(cacheKey) : false;

			if (value) {
				// In the cache? Get it, parse it to json if the dataType is JSON,
				// and call the completeCallback with the fetched value.
				if (dataType.toLowerCase().indexOf("json") !== -1) value = JSON.parse(value);
				return {
					send: function(headers, completeCallback) {
						var response = {};
						response[dataType] = value;
						completeCallback(200, "success", response, "");
					},
					abort: function() {
						console.log("Aborted ajax transport for json cache.");
					}
				};
			}
		}
	});
})(jQuery, window);

//Header Hoofdmenu	
if ($(".header__link-panel").length) {
	$(".header__link-panel").on("click", function() {
		$(this).toggleClass("active");
		$(".main-menu").toggleClass("open");
		$("body").toggleClass("open");
	});

	$("#menu-main-menu li, #menu-main-menu-eng li").on("click", function() {
		$(this).toggleClass("active")
			.children("ul.sub-menu")
			.addClass("open-menu")
			.end()
			.siblings()
			.removeClass("active")
			.find("ul.sub-menu")
			.removeClass("open-menu");
	});

	$("#menu-main-menu-subsubmenu li").on("click", function() {
		$(this).toggleClass("active")
			.children("ul.subsub-menu")
			.addClass("open-menu")
			.end();
	});

	$("#menu-main-menu .nav-back__btn, #menu-main-menu-eng .nav-back__btn").on("click", function() {
		var num = $(".sub-menu.open-menu").map(function() {
			return $(".sub-menu.open-menu").data("depth");
		}).get();

		var highest = Math.max.apply(Math, num);
		var x = $(".sub-menu.open-menu").filter(function(){
			return $(".sub-menu.open-menu").data("depth") == highest;
		});   
		( highest == 0) ? x
			.removeClass("open-menu") 
			.parent("li")
			.removeClass("active")
			: x
				.removeClass("open-menu");
	});

	$("#menu-main-menu li a").not("[href=\"#\"]").not("[href=\"javascript:void(0);\"]").on("click", function() {
		$(".header__link-panel").toggleClass("active");
		$(".main-menu").toggleClass("open", 280);
		$("body").toggleClass("open");
	});
	$(document).click(function(e) {
		if (!$(e.target).closest("#menu-main-menu").length) {
			$("#menu-main-menu li").removeClass("active")
				.children("ul.sub-menu")
				.removeClass("open-menu");
		}
	});
}

if ($("[data-bs-toggle='modal'], [data-toggle='modal']").length) {
	import(/* webpackChunkName: "modal" */ "./partials/modal.js");
}

if ($(".header__menu").length) {
	import(/* webpackChunkName: "menu" */ "./partials/menu.js");
}

if ($( ".select2-multiple, .select2-vcards, .select2-checkbox, .select2-single, .filter select" ).length) {
	import(/* webpackChunkName: "select2" */ "./partials/select2").then(action => { 
		action.InitSelect2();
	});
}

if ($(".template__content-left .team__container").length) { 
	import(/* webpackChunkName: "calendar" */ "./partials/tippy.js").then(action => { 
		action.initTippy();
	});
	jQuery(".team__container input[type=radio]").on("click", function() {
		jQuery(".contact_name").html($(this).data("contact_name"));
		jQuery("#input_100_6").val($(this).data("contact_mail"));
	});
}

function pushquerystate(filter, val) {
	var current_url = new URL(window.location.href);
	var params = new URLSearchParams(current_url.search);
	var base_url = window.location.origin + window.location.pathname;
	if(filter == "team") {
		filter = "filter_team";
	} else if (filter == "sector") {
		filter = "filter_sector"; 
	} else if (filter == "search") {
		filter = "filter_search";
	} else if (filter == "expertise") {
		filter = "filter_expertise";
	} else if (filter == "category") {
		filter = "filter_category";
	}	
	if(params.getAll(filter) != null) {
		params.set(filter, val);
	} else {
		params.append(filter, val);
	}
	if(val == null || val == "") {
		params.delete(filter);
	}
	var url = (params.size > 0) ? (base_url + "?" + params.toString()) : base_url;
	history.pushState({}, "", url);
}

if(new URL(document.location).searchParams.has("filter", "value")) {
	let params = new URL(document.location).searchParams;
	let filter = params.get("filter");
	let value = params.get("value");
	if (filter == "category") {
		$(".category-filter").find("li a[data-category='" + value + "']").click();
	} else {
		$("." + filter + "-filter__list").val(value).trigger("change");
	}
}
	
//Track Record
function getListTransfers(paged) {
	var data = {};
	data["year"] = $(".year-filter__list").val() == null ? "" : $(".year-filter__list").val();
	$.ajax({
		type: "post",
		localCache: true,
		cacheTTL: 1,
		dataType: "json",
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_list_transfers",
			paged: paged,
			filterData: data,
		},
		beforeSend: function beforeSend() {
			$(".transfers-list__items").append("<div class=\"loading\"></div>");
		},
		success: function success(response) {
			var result = JSON.parse(JSON.stringify(response));

			if (result.data !== null) {
				$(".transfers-list__items").html(result.data);
				$(window).scrollTop(0);
			}

			if (result.pagination !== null) {
				$(".transfers-list__pagination").html(result.pagination);
				alterPagination();
			}
		}
	});
}
if ($(".page-template-template-transfers").length && (new URL(document.location).searchParams.has("search") == true)) {
	getListTransfers(new URL(document.location).searchParams.get("search"));
} else if ($(".transfers-list__items").length && $(".page-template-template-transfers").length && $(".alreadyloaded.transfers-list__items").length <= 0 && !document.location.pathname.includes("page")) {
	getListTransfers();
}

$( ".page-template-template-transfers .transfer-content a" ).on( "click", function(event) {
	event.stopImmediatePropagation();
	return false;
});

$( ".page-template-template-transfers [class*='item__text'] p > a" ).on( "click", function(event) {
	event.stopImmediatePropagation();
	return window.open( $(this).attr("href"),"_blank"); 
});

$( ".page-template-template-transfers [class*='item__text'].content" ).not( $( "p > a" ) ).on( "click", function(event) {
	event.preventDefault();
	$(this).closest("[class*='item__text'].content").hide();
	$(this).siblings("[class*='item__text'].excerpt").show();
});

$( ".page-template-template-transfers [class*='item__text'].excerpt" ).not( $( "p > a" ) ).on( "click", function(event) {
	event.preventDefault();
	var height = $(this).closest("[class*='item__text'].excerpt").height();
	$(this).closest("[class*='item__text'].excerpt").hide();
	$(this).siblings("[class*='item__text'].content").css({
		"maxHeight": height
	});
	$(this).siblings("[class*='item__text'].content").show();
	$(this).siblings("[class*='item__text'].content").animate({
		"maxHeight": "1500px"
	}, 400, function() {});
});


//News
function getListNews(paged) {
	var data = {};
	data["search"] = $(".search-form__text").val() == null ? "" : $(".search-form__text").val();
	data["expertise"] = $(".expertise-filter__list").val() == null ? "" : $(".expertise-filter__list").val();
	data["sector"] = $(".sector-filter__list").val() == null ? "" : $(".sector-filter__list").val();
	data["team"] = $(".team-filter__list").val() == null ? "" : $(".team-filter__list").val();
	$.ajax({
		type: "post",
		localCache: true,
		cacheTTL: 1,
		dataType: "json",
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_list_news",
			paged: paged,
			filterData: data,
		},
		beforeSend: function() {
			$(".news-list__items").append("<div class=\"loading\"></div>");
		},
		success: function(response) {
			var result = JSON.parse(JSON.stringify(response));
			if (result.data !== null) {
				$(".news-list__items").html(result.data);
				$(window).scrollTop(0);
			}
			if (result.pagination !== null) {
				$(".news-list__pagination").html(result.pagination);
				alterPagination();
			}
		},
	});
}
$(".page-template-template-news .search-form__text").on("keydown", function(e) {
	if (e.keyCode === 13) {
		e.preventDefault();
		getListNews(1);
	}
});
$(".page-template-template-news .sidebar-agenda__search-button").on("click", function() {
	getListNews(1);
});
$(".page-template-template-news .expertise-filter__list").on("change", function() {
	pushquerystate("expertise", $(".expertise-filter__list").val());
	getListNews(1);
});
$(".page-template-template-news .team-filter__list").on("change", function() {
	pushquerystate("team", $(".team-filter__list").val());
	getListNews(1);
});
$(".page-template-template-news .sector-filter__list").on("change", function() {
	pushquerystate("sector", $(".sector-filter__list").val()); 
	getListNews(1);
});
$(".page-template-template-news .sidebar-agenda__search-button").on("click", function() {
	pushquerystate("search", $(".search-form__text").val()); 	
	getListNews(1);
});
if ($(".page-template-template-news").length && (new URL(document.location).searchParams.has("search") == true)) {
	getListNews(1, (new URL(document.location).searchParams.get("search")));
} else if ($(".news-list__items").length && $(".page-template-template-news").length && $(".alreadyloaded.news-list__items").length <= 0) {
	getListNews(1);
}

//Publication
function getListPublications(paged) {
	var bibliotheeksoort = $(".template__content").attr("data-bibliotheeksoort") == null ? "" : $(".template__content").attr("data-bibliotheeksoort");
	var data = {};
	data["search"] = $(".search-form__text").val() == null ? "" : $(".search-form__text").val();
	data["expertise"] = $(".expertise-filter__list").val() == null ? "" : $(".expertise-filter__list").val();
	data["sector"] = $(".sector-filter__list").val() == null ? "" : $(".sector-filter__list").val();
	data["team"] = $(".team-filter__list").val() == null ? "" : $(".team-filter__list").val();
	data["category"] = ($(".category-filter li.active a").data("category") == null) ? "" : $(".category-filter li.active a").data("category");
	$.ajax({
		type: "post",
		dataType: "json",
		localCache: true,
		cacheTTL: 1,
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_list_publications",
			paged: paged,
			filterData: data,
			bibliotheeksoort: bibliotheeksoort,
		},
		beforeSend: function() {
			$(".publication-list__items").append("<div class=\"loading\"></div>");
		},
		success: function(response) {
			var result = JSON.parse(JSON.stringify(response));
			if (result.data !== null) {
				$(".publication-list__items").html(result.data);
				$(window).scrollTop(0);
			}
			if (result.pagination !== null) {
				$(".publication-list__pagination").html(result.pagination);
				alterPagination();
			}
		},
	});
}
if ($(".page-template-template-publication .category-filter").length) {
	$(".page-template-template-publication .category-filter ul li a").attr("href", "javascript:void(0)");
}
$(".page-template-template-publication .expertise-filter__list").on("change", function() {
	pushquerystate("expertise", $(".expertise-filter__list").val());
	getListPublications(1);
});
$(".page-template-template-publication .team-filter__list").on("change", function() {
	pushquerystate("team", $(".team-filter__list").val());
	getListPublications(1);
});
$(".page-template-template-publication .sector-filter__list").on("change", function() {
	pushquerystate("sector", $(".sector-filter__list").val()); 
	getListPublications(1);
});
$(".page-template-template-publication .sidebar-agenda__search-button").on("click", function() {
	pushquerystate("search", $(".search-form__text").val()); 	
	getListPublications(1);
});
$(".page-template-template-publication .category-filter").on("click", "li", function() {
	if ($(this).hasClass("active")) {
		$(this).removeClass("active");
	} else {
		$(".category-filter li").removeClass("active");
		$(this).addClass("active");
	}
	pushquerystate("category", $("this").attr("data-category")); 
	getListPublications(1);
});
if ($(".page-template-template-publication").length && (new URL(document.location).searchParams.has("search") == true)) {
	getListPublications(1, (new URL(document.location).searchParams.get("search")));
} else if ($(".publication-list__items").length && $(".page-template-template-publication").length && $(".alreadyloaded.publication-list__items").length <= 0) {
	getListPublications(1);
}
$(".page-template-template-publication .search-form__text").on("keydown", function(e) {
	if (e.keyCode === 13) {
		e.preventDefault();
		getListPublications(1);
	}
});

//Search
function getSearch(paged) {
	var pageID = $(".template__content").attr("data-pageID");
	var query = $(".sidebar-agenda__search-form #general_search").val();
	var url = window.location.href;
	var sitepart = $("#sitepart").val();
	var param = getLastURLPart(url);
	var expertise = $(".expertise-filter__list").val();
	var team = $(".team-filter__list").val();
	if (param != null) {
		query = param;
	}
	$.ajax({
		type: "GET",
		dataType: "json",
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_search",
			paged: paged,
			pageID: pageID,
			query: query,
			sitepart: sitepart,
			expertise: expertise,
			team: team
		},
		beforeSend: function() {
			$(".search__items").append("<div class=\"loading\"></div>");
			//history.pushState(null, null, query);
		},
		success: function(response) {
			var result = JSON.parse(JSON.stringify(response));
			if (result.data !== null) {
				$(".search__items").html(result.data);
			}
			if (result.pagination !== null) {
				$(".search__pagination").html(result.pagination);
				alterPagination();
			}
		},
	});
}

function getLastURLPart(url) {
	var part = url.match(/.*\/(.+)/);
	if (!part) {
		return null;
	}
	return part[1];
}
if ($("#select2-date option:selected").val() == "datepicker") {
	import(/* webpackChunkName: "datepicker" */ "./partials/datepicker.js").then(action => { 
		action.InitDatePicker();
	});
}
	
$(".search #sitepart").on("change", function() {
	getSearch(1);
});
$(".search #select2-date").on("change", function() {
	getSearch(1);
});
$("body.search #general_search").on("keydown", function(e) {
	if (e.keyCode === 13) {
		e.preventDefault();
		getSearch(1);
	}
});
$("body.search .search-button").on("click", function() {
	getSearch(1);
});
if ($("body.search").length && ($("#general_search").val() != null)) {
	getSearch(1);
}
$(".search .expertise-filter__list, .search .team-filter__list").on("change", function() {
	getSearch(1);
});
//Searchbar
$("#search").click(function(e) {
	e.preventDefault();
	$(".search_box").toggleClass("active");
});

//Pagination
function createRel( prevnext, href) {
	var link = document.createElement("link");	
	link = $(link).attr("rel", prevnext);
	link = $(link).attr("href", href);
	link = $(link).addClass("yoast-seo-meta-tag");
	$("link[rel=\"canonical\"]").after(link);
}

function removeRel( prevnext ) {
	$("link[rel=\"" + prevnext + "\"]").remove();
	return null;
}

$(".ajax-paginate").on("click", "a.page-numbers", function(event) {
	event.preventDefault();
	var link_href 		= $(this).attr("href");
	var link_array	 	= link_href.split("/");
	var array_index 	= link_array.length - 2;
	var clicked_page 	= link_array[array_index];
	var title			= "";
	title 				= $(document).attr("title").replace(/Page \d+/, "Page " + clicked_page);
	title				= $(document).attr("title").replace(/Pagina \d+/, "Pagina " + clicked_page);
	$("meta[property='og:title']").attr("content", title);
	if ($(".agenda-list__items").length) {
		getListCalendars(clicked_page);
	}
	if ($(".news-list__items").length) {
		getListNews(clicked_page);
	}
	if ($(".search-results").length) {
		getSearch(clicked_page);
	}
	if ($(".publication-list__items").length) {
		getListPublications(clicked_page);
	}
	if ($(".transfers-list__items").length) {
		getListTransfers(clicked_page);
	}
	document.title = title;	
	history.pushState({}, title, link_href);
	
	$("link[rel=\"canonical\"]").attr("href", link_href);
	$("body, html").animate({
		scrollTop: 0,
	}, 1500);
	return false;
});

function alterPagination() {
	($("a.prev.page-numbers").length < 1) 		? 			removeRel("prev") : 
		($("link[rel=\"prev\"]").length < 1) 		? 			createRel("prev", $("a.prev.page-numbers").attr("href")) :
			$("link[rel=\"prev\"]").attr("href", $("a.prev.page-numbers").attr("href"));
		
	($("a.next.page-numbers").length < 1) 		? 			removeRel("next") : 
		($("link[rel=\"next\"]").length < 1) 		?			createRel("next", $("a.next.page-numbers").attr("href")) : 
			$("link[rel=\"next\"]").attr("href", $("a.next.page-numbers").attr("href"));
}

//Onze mensen
function getListTeams() {
	var data = {};
	data["expertise"] = $("#expertise-filter").val();
	data["location"] = $("#location-filter").val();
	data["position"] = $("#position-filter").val();
	data["staff"] = $("#staff-filter").val();
	data["specialty"] = $("#specialty-filter").val();
	data["sector"] = $("#sector-filter").val();
	$.ajax({
		type: "post",
		localCache: true,
		cacheTTL: 1,
		async: false,
		dataType: "json",
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_list_teams",
			filterData: data
		},
		beforeSend: function() {
			$(".teams-inner").append("<div class=\"loading\"></div>");
		},
		success: function(response) {
			var result = JSON.parse(JSON.stringify(response));
			if (result.data !== null) {
				$(".teams-inner").html(result.data);
				lazy();
			}
		},
	});
}

$(".page-template-template-team .filter select").on("change", function() {
	getListTeams();
});
if ($(".page-template-template-team").length && (new URL(document.location).searchParams.has("search") == true)) {
	getListTeams(new URL(document.location).searchParams.get("search"), false);}

if($(".page-template-template-team").length && $(".alreadyloaded.teams-inner").length <= 0) {
	getListTeams();
} else if ($(".teams-inner").length) {
	lazy();
}

//Vacatures
function getListVacancies() {
	var data = {};
	data["expertise"] = $("#expertise-filter").val();
	data["location"] = $("#location-filter").val();
	$.ajax({
		type: "post",
		localCache: true,
		cacheTTL: 1,
		dataType: "json",
		url: location.origin + "/wp-admin/admin-ajax.php",
		data: {
			action: "get_list_vacancies",
			filterData: data
		},
		beforeSend: function() {
			$(".vacancies-inner").append("<div class=\"loading\"></div>");
		},
		success: function(response) {
			var result = JSON.parse(JSON.stringify(response));
			if (result.data !== null) {
				$(".vacancies-inner").html(result.data);
			}
		},
	});
}
$(".page-template-template-vacancies .expertise-filter__list").on("change", function() {
	pushquerystate("expertise", $(".expertise-filter__list").val());
	getListVacancies(null, false);
});

$(".page-template-template-vacancies .location-filter__list").on("change", function() {
	pushquerystate("location", $(".location-filter__list").val()); 	
	getListVacancies(null, false);
});

if ($(".page-template-template-vacancies").length && (new URL(document.location).searchParams.has("search") == true)) {
	getListVacancies(new URL(document.location).searchParams.get("search"), false);
}
if ($(".page-template-template-vacancies").length && $(".alreadyloaded.vacancies-inner").length <= 0) {
	getListVacancies(null, false);
} else if ($(".vacancies-inner").length) {
	lazy();
}
//Reset alle Caches (WP Adminbar)
$(document).on("click", "#wp-admin-bar-empty_cache", function() {
	$("#loading-screen").css("display", "");
	localStorage.clear();
	$("#wp-admin-bar-litespeed-purge-all a.ab-item")[0].click();
});


//CarouselActions
if ($(".banner-carousel, .expertise-carousel").length) {
	import( /*webpackChunkName:"carousel"*/"/partials/carousel.js").then( action => {
		action.initCarousel();
	});
}

//CalendarActions
$(".page-template-template-events .search-form__text").on("keydown", function(e) {
	if (e.keyCode === 13) {
		e.preventDefault();
		getListCalendars(1);
	}});
	
function getListCalendars(paged) {
	import( /*webpackChunkName:"calendar"*/"/partials/calendar.js").then( action => {
		action.getListCalendars(paged);
		$(window).scrollTop(0);
		alterPagination();
	});}

if ($("#calendar").length) { 
	import( /*webpackChunkName:"calendar"*/"/partials/calendar.js").then( action => {
		action.initCalendar(); 
	});}

if ($(".page-template-template-events").length && (new URL(document.location).searchParams.has("search") == true)) {
	import( /*webpackChunkName:"calendar"*/"/partials/calendar.js").then( action => {
		action.getListCalendars(1, (new URL(document.location).searchParams.get("search")));});}
else if ($(".agenda-list__items").length && $(".page-template-template-events").length && $(".alreadyloaded.agenda-list__items").length <= 0) {
	getListCalendars(1);}
		
$(".page-template-template-events .team-filter__list").on("change", function() {
	pushquerystate("team", $(".team-filter__list").val());
	getListCalendars(1);
});

$(".page-template-template-events .sector-filter__list").on("change", function() {
	pushquerystate("sector", $(".sector-filter__list").val()); 
	getListCalendars(1);
});

$(".page-template-template-events .expertise-filter__list").on("change", function() {
	pushquerystate("expertise", $(".expertise-filter__list").val());
	getListCalendars(1);
});

$(".page-template-template-events .sidebar-agenda__search-button").on("click", function() {
	pushquerystate("search", $(".search-form__text").val()); 	
	getListCalendars(1);
});

if($(".page-template-template-partnerschap").length) {
	jQuery.extend( jQuery.expr[ ":" ], {
		data: jQuery.expr.createPseudo ?
			jQuery.expr.createPseudo(function( dataName ) {
				return function( elem ) {
					return !!jQuery.data( elem, dataName );
				};
			}) :
		// support: jQuery <1.8
			function( elem, i, match ) {
				return !!jQuery.data( elem, match[ 3 ] );
			}
	});
	jQuery( ".category-filter li" ).on( "click", function() {
		var x = jQuery(this).data("div");
		jQuery( ".category-filter li" ).removeClass("active");
		jQuery(this).addClass("active");
		jQuery( ".partnership_content span" ).removeClass("show");
		jQuery( ".partnership_content span[data-div='"+x+"'] ").addClass("show");
	});
}
if(jQuery(".alreadyloaded").length) {
	jQuery(".alreadyloaded").removeClass("alreadyloaded");
}

jQuery(document).ready(function() {
	jQuery(document).bind("gform_confirmation_loaded", function(event, formID) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			"event": "formSubmission",
			"formID": formID
		});
	});
});